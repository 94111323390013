import { SocialButton } from './style'
import IconFacebook from './assets/icon-fb.svg'
import IconTwitter from './assets/icon-twitter.svg'
import { env } from '~/config/env'
import { routes } from '~/config/routes'

type Props = {
  slug: string
  fundraiserId?: string
}

export const SocialButtons = ({ slug, fundraiserId }: Props) => {
  const shareUrl = encodeURIComponent(
    env.baseUrl + routes.fundraisingPage.home(slug, fundraiserId).as
  )

  return (
    <>
      <SocialButton
        color="#1877F2"
        href={`https://facebook.com/sharer/sharer.php?u=${shareUrl}`}
        target="_blank"
        rel="noopener"
        aria-label="Share on Facebook"
        className="mb-4 lg:mb-0"
        style={{ marginRight: 16 }}
      >
        <IconFacebook />
        Share
      </SocialButton>

      <SocialButton
        color="#1DA1F2"
        href={`https://twitter.com/intent/tweet/?text=${shareUrl}`}
        target="_blank"
        rel="noopener"
        aria-label="Share on Twitter"
      >
        <IconTwitter />
        Tweet
      </SocialButton>
    </>
  )
}
