const trackConversion = (
  type: 'Fundraising Page' | 'Donation Page',
  amount: number,
  title: string,
  googleAdsConversionId: Maybe<string>,
  googleAdsConversionLabel: Maybe<string>
) => {
  const dolars = Math.round(amount / 100)

  if (window.gtag) {
    window.gtag('event', 'Donate', {
      event_category: type,
      event_label: title,
      value: dolars,
    })
  }

  if (window.fbq) {
    window.fbq('track', 'Donate', { currency: 'USD', value: dolars })
  }

  if (window.gtag && googleAdsConversionId) {
    window.gtag('event', 'conversion', {
      send_to: `${googleAdsConversionId}/${googleAdsConversionLabel}`,
      value: dolars,
      currency: 'USD',
    })
  }
}

const trackPageview = (url: string) => {
  if (window.ga) {
    window.ga('send', 'pageview', url)
  }

  if (window.fbq) {
    window.fbq('track', 'PageView')
  }
}

export { trackConversion, trackPageview }
