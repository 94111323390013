import { useRef, useState, useEffect } from 'react'

export const useIntersectionObserver = (options?: IntersectionObserverInit) => {
  const observerRef = useRef<IntersectionObserver>()
  const [ref, setRef] = useState<HTMLDivElement | null>(null)
  const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null)

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => {
      setEntry(entry)
    }, options)

    return () => {
      observerRef.current?.disconnect()
    }
  }, [])

  useEffect(() => {
    if (ref) {
      observerRef.current?.observe(ref)
      return () => {
        observerRef.current?.unobserve(ref)
      }
    }
    return
  }, [ref])

  return [entry, setRef] as const
}
