import { Amount } from '~/components/amount'
import { Avatar } from '~/components/avatar'
import { Box, Flex } from '~/components/layout'
import { RelativeTime } from '~/components/relative-time'
import { Meta } from '~/components/typography'
import { routes } from '~/config/routes'
import { colors } from '~/styles/colors'
import { fonts } from '~/styles/fonts'
import { DonationRecurrenceInterval } from '~/types/graphql'

import { CommunityQuery } from '../../graphql/community'
import { PostWrapper } from './style'

type Props = {
  slug: string
  post: CommunityQuery['community']['posts'][0]
  isFundraiser: boolean
}

export const Post = ({ slug, post, isFundraiser }: Props) => {
  const {
    created,
    comment,
    reply,
    dedication,
    fundraiser,
    recurrenceInterval,
    donation,
  } = post

  const author = post.anonymous
    ? 'Donor wishes to stay anonymous'
    : post.author!.name

  return (
    <Box paddingTop={32} paddingBottom={28} borderTop={colors.border}>
      <PostWrapper replied={Boolean(reply)}>
        <Avatar
          size={40}
          user={
            post.anonymous
              ? { firstName: 'A' }
              : {
                  firstName: author.split(' ')[0],
                  lastName: author.split(' ')[1],
                }
          }
          marginRight={16}
        />
        <Box flex={1}>
          <Flex justifyContent="space-between" alignItems="center">
            <Meta>
              {author}
              {isFundraiser && fundraiser && (
                <span
                  style={{
                    fontWeight: fonts.text.regular,
                  }}
                >
                  {' '}
                  via{' '}
                  <a
                    href={routes.fundraisingPage.home(slug, fundraiser.id).as}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="underline"
                  >
                    {fundraiser.name}
                  </a>
                </span>
              )}
            </Meta>
            <Meta marginTop={4}>
              <Amount>{donation}</Amount>
              {recurrenceInterval === DonationRecurrenceInterval.Monthly &&
                ' per month'}
            </Meta>
          </Flex>
          {dedication && (
            <Meta regular color={colors.slateGray} marginBottom={4}>
              made a donation{' '}
              {dedication.type === 'in_honor' ? 'in honor' : 'in memory'} of{' '}
              {dedication.firstName} {dedication.lastName}
            </Meta>
          )}
          {comment && <Meta regular>{comment}</Meta>}
          <Meta regular color={colors.slateGray} marginTop={6}>
            <RelativeTime>{created}</RelativeTime>
          </Meta>
        </Box>
      </PostWrapper>

      {reply && (
        <Box paddingLeft={56}>
          <Flex paddingTop={28} marginTop={28} borderTop={colors.border}>
            <Avatar
              size={40}
              user={
                reply.author?.name
                  ? {
                      firstName: reply.author?.name.split(' ')[0],
                      lastName: reply.author?.name.split(' ')[1],
                    }
                  : { firstName: 'A' }
              }
              marginRight={16}
            />
            <Box flex={1}>
              <Meta>{reply.author?.name}</Meta>
              {reply.text && (
                <Meta regular marginTop={4}>
                  {reply.text}
                </Meta>
              )}
              <Meta regular color={colors.slateGray} marginTop={6}>
                <RelativeTime>{reply.created}</RelativeTime>
              </Meta>
            </Box>
          </Flex>
        </Box>
      )}
    </Box>
  )
}
