import { ReactNode, useEffect } from 'react'
import { Wrapper, Main, Header, LogoImage } from './style'
import { Flex } from '~/components/layout'
import { PageBySlugQuery } from '~/graphql/page-by-slug'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { trackPageview } from '~/utils/integrations'
import { PageFooter } from '~/components/page-footer'
import {
  GoogleTagManagerBodyIframe,
  GoogleTagManagerHeadScript,
} from '~/components/google-analytics'

const PreviewLogo = dynamic(import('./components/preview-logo'), { ssr: false })

type Props = {
  page: PageBySlugQuery['pageBySlug']
  children: ReactNode
  leftContent?: ReactNode
  rightContent?: ReactNode
}

export const FundraisingPageLayout = ({
  page,
  children,
  leftContent = null,
  rightContent = null,
}: Props) => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      trackPageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  const { nonprofit, color } = page

  const renderLogo = () => {
    if (nonprofit.logoUrl) {
      const logo = (
        <LogoImage
          width={286}
          height={96}
          src={nonprofit.logoUrl}
          alt={`${page.nonprofit.name} logo`}
        />
      )

      if (!nonprofit.websiteUrl) {
        return logo
      }

      return (
        <a
          href={nonprofit.websiteUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {logo}
        </a>
      )
    }

    return <PreviewLogo pageId={page.id} />
  }

  return (
    <>
      <Head>
        {page.googleTagManagerId && (
          <GoogleTagManagerHeadScript
            googleTagManagerId={page.googleTagManagerId}
          />
        )}
        {(page.googleAnalyticsId || page.googleAdsConversionId) && (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${
                page.googleAnalyticsId || page.googleAdsConversionId
              }`}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  ${
                    page.googleAnalyticsId
                      ? `gtag('config', '${page.googleAnalyticsId}');`
                      : ''
                  }
                  ${
                    page.googleAdsConversionId
                      ? `gtag('config', '${page.googleAdsConversionId}');`
                      : ''
                  }
                `,
              }}
            />
          </>
        )}
        {page.facebookPixelId && (
          <>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '${page.facebookPixelId}');
                  fbq('track', 'PageView');
                `,
              }}
            />
            <noscript
              dangerouslySetInnerHTML={{
                __html: `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${page.facebookPixelId}&ev=PageView&noscript=1" />`,
              }}
            />
          </>
        )}
      </Head>

      <Wrapper color={color}>
        <Header>
          <Flex flex={1}>{leftContent}</Flex>
          <Flex
            flex={1}
            justifyContent={['flex-end', 'flex-end', 'flex-end', 'center']}
          >
            {renderLogo()}
          </Flex>
          <div className="hidden lg:flex justify-end flex-1">
            {rightContent}
          </div>
        </Header>
        <Main>{children}</Main>
        <PageFooter />
        {page.googleTagManagerId && (
          <GoogleTagManagerBodyIframe
            googleTagManagerId={page.googleTagManagerId}
          />
        )}
      </Wrapper>
    </>
  )
}
