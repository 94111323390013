import { ParsedUrlQuery } from 'querystring'

export const getQueryParameter = (
  query: ParsedUrlQuery,
  param: string
): string | undefined => {
  const value = query[param]
  return value
    ? typeof value === 'string'
      ? value
      : value[value.length - 1]
    : undefined
}
