import styled from 'styled-components'
import { colors } from '~/styles/colors'

export const PostWrapper = styled.div<{ replied: boolean }>(props => ({
  position: 'relative',
  display: 'flex',
  ...(props.replied && {
    '::before': {
      content: "''",
      position: 'absolute',
      width: 1,
      background: colors.whiteFog,
      top: 40,
      left: 19,
      bottom: -76,
    },
    '::after': {
      content: "''",
      position: 'absolute',
      height: 1,
      background: colors.whiteFog,
      left: 19,
      bottom: -77,
      width: 37,
    },
  }),
}))
