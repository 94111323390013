import { darken, lighten } from 'polished'
import styled from 'styled-components'
import { colors } from '~/styles/colors'
import { media } from '~/styles/media'

export const Wrapper = styled.div<{ color: string }>(props => ({
  background: '#fff',
  '--kindest-primary-default': props.color,
  '--kindest-primary-light': lighten(0.1, props.color),
  '--kindest-primary-ultralight': lighten(0.3, props.color),
  '--kindest-primary-dark': darken(0.1, props.color),
  // paddingBottom: 50,

  // [media.medium]: {
  //   paddingBottom: 150,
  // },
  minHeight: '100vh',

  display: 'flex',
  flexDirection: 'column',
}))

export const Main = styled.main({
  flex: 1,
  [media.large]: {
    paddingBottom: '2.5rem',
  },
})

export const Header = styled.header({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 72,
  borderBottom: colors.border,
  padding: '10px 20px',

  [media.medium]: {
    padding: '20px 40px',
    height: 120,
  },
})

export const LogoImage = styled.img({
  objectFit: 'contain',
  width: 100,
  maxHeight: 40,
  objectPosition: 'right',

  [media.medium]: {
    width: 286,
    maxHeight: 96,
    objectPosition: 'center',
  },
})
