import { formatAmount } from '~/components/amount'
import { Grid } from '~/components/layout'
import { PageBySlugQuery } from '~/graphql/page-by-slug'
import { DonationRecurrenceInterval } from '~/types/graphql'

import { PreviewTiers } from './components/preview-tiers'
import { Tier } from './components/tier'

type Props = {
  closed: boolean
  slug: string
  donationTiers: PageBySlugQuery['pageBySlug']['donationTiers']
  fundraiserId?: string
  allowCustomAmount: boolean
}

// TODO: lock to only 4 tiers per FP

const labels = [
  {
    label: 'One-Time',
    value: DonationRecurrenceInterval.None,
  },
  {
    label: 'Monthly',
    value: DonationRecurrenceInterval.Monthly,
  },
  {
    label: 'Quarterly',
    value: DonationRecurrenceInterval.Quarterly,
  },
  {
    label: 'Yearly',
    value: DonationRecurrenceInterval.Yearly,
  },
]

export const DonationTiers = ({
  closed,
  slug,
  donationTiers,
  fundraiserId,
  allowCustomAmount,
}: Props) => {
  return (
    <Grid gridRowGap={20}>
      {donationTiers.length === 0 && <PreviewTiers />}
      {donationTiers.map(tier => (
        <Tier
          key={tier.id}
          slug={slug}
          fundraiserId={fundraiserId}
          tierId={tier.id}
          heading={`${formatAmount(tier.amount)}${
            ' ' + labels.find(i => i.value == tier.recurrenceInterval)?.label
          }`}
          description={tier.description}
          disabled={closed}
        />
      ))}
      {allowCustomAmount && (
        <Tier
          slug={slug}
          fundraiserId={fundraiserId}
          tierId="custom"
          heading="Donate Custom Amount"
          description="Choose your custom amount. Every dollar makes a difference."
          disabled={closed}
        />
      )}
    </Grid>
  )
}
