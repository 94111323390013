import { useState } from 'react'
import { Amount } from '~/components/amount'
import { Flex } from '~/components/layout'
import { Meta } from '~/components/typography'
import { PageBySlugQuery } from '~/graphql/page-by-slug'

import useCountdown from '../../hooks/use-countdown'
import IconArrowLeft from './assets/icon-arrow-left.svg'
import IconArrowRight from './assets/icon-arrow-right.svg'
import {
  Body,
  BodyText,
  CountDown,
  CountDownBox,
  CountDownBoxHeader,
  CountDownBoxValue,
  GoalAmount,
  Header,
  HeaderButton,
  Progress,
  ProgressBar,
  Wrapper,
} from './style'

type Props = {
  currentAmount: number
  goals: PageBySlugQuery['pageBySlug']['goals']
}

export const Goals = ({ currentAmount, goals }: Props) => {
  const [index, setIndex] = useState(() => {
    for (const [index, goal] of goals.entries()) {
      if (currentAmount < goal.amount) {
        return index
      }
    }
    return goals.length - 1
  })

  const maxGoals = goals.length
  const goal = goals[index]
  let { days, hours, minutes, seconds } = useCountdown(
    goal.endDate != undefined ? goal.endDate : new Date().getTime()
  )

  const handlePrevious = () => {
    if (index - 1 >= 0) {
      setIndex(index - 1)
    }
  }

  const handleNext = () => {
    if (index + 1 < maxGoals) {
      setIndex(index + 1)
    }
  }

  const progress = Math.min(100 * (currentAmount / goal.amount), 100)

  return (
    <Wrapper>
      <Header>
        <Meta>Goals</Meta>
        <Flex alignItems="center">
          <Meta regular marginRight={16}>
            {index + 1} of {maxGoals}
          </Meta>
          <HeaderButton onClick={handlePrevious}>
            <IconArrowLeft />
          </HeaderButton>
          <HeaderButton onClick={handleNext}>
            <IconArrowRight />
          </HeaderButton>
        </Flex>
      </Header>
      <Body>
        {currentAmount === 0 ? (
          <GoalAmount>
            <strong>
              <Amount>{goal.amount}</Amount>
            </strong>
          </GoalAmount>
        ) : (
          <>
            <GoalAmount>
              <strong>
                <Amount>{currentAmount}</Amount>
              </strong>{' '}
              of <Amount>{goal.amount}</Amount>
            </GoalAmount>
            <ProgressBar>
              <Progress style={{ width: `${progress}%` }} />
            </ProgressBar>
          </>
        )}
        <BodyText>{goal.description}</BodyText>
        {goal.endDate != null && goal.endDate != 0 ? (
          <CountDown>
            <CountDownBox disabled={goal.endDate < Date.now()}>
              <CountDownBoxValue> {days}</CountDownBoxValue>
              <CountDownBoxHeader>Days</CountDownBoxHeader>
            </CountDownBox>
            <CountDownBox disabled={goal.endDate < Date.now()}>
              <CountDownBoxValue> {hours}</CountDownBoxValue>
              <CountDownBoxHeader>Hrs</CountDownBoxHeader>
            </CountDownBox>
            <CountDownBox disabled={goal.endDate < Date.now()}>
              <CountDownBoxValue> {minutes}</CountDownBoxValue>
              <CountDownBoxHeader>Min</CountDownBoxHeader>
            </CountDownBox>
            <CountDownBox disabled={goal.endDate < Date.now()}>
              <CountDownBoxValue> {seconds}</CountDownBoxValue>
              <CountDownBoxHeader>Sec</CountDownBoxHeader>
            </CountDownBox>
          </CountDown>
        ) : (
          <></>
        )}
      </Body>
    </Wrapper>
  )
}
