import styled from 'styled-components'
import { fonts } from '~/styles/fonts'
import { media } from '~/styles/media'

export const SocialButton = styled.a<{ color: string }>(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '0.875rem',
  fontWeight: fonts.text.medium,
  height: 40,
  border: `1px solid ${props.color}`,
  borderRadius: 2,
  color: props.color,
  width: '100%',

  [media.min(1000)]: {
    width: 130,
  },

  svg: {
    marginRight: 8,
    color: props.color,
  },

  '&:hover': {
    background: props.color,
    color: 'white',

    svg: {
      color: 'white',
    },
  },
}))
