import Link from 'next/link';
import { Flex, Grid } from '~/components/layout';
import { Meta } from '~/components/typography';
import { routes } from '~/config/routes';

import KindestLogo from './assets/kindest-logo.svg';
import { Wrapper } from './style';

type Props = {}

export const PageFooter = ({}: Props) => {
  return (
    <Wrapper>
      <Grid
        gridAutoFlow={[null, 'column', 'column']}
        gridGap={[16, 32, 40]}
        justifyItems={['center', null]}
        marginBottom={[32, 32, 0, 0]}
      >
        {/* <Meta as="a" href="/faq" target="_blank" regular>
          Donor FAQ
        </Meta> */}
        {/* <Meta as="a" href="mailto:support@kindest.com" target="_blank" regular>
          Donor Support
        </Meta> */}
        {/* <Link href={routes.legal.donor.faq} passHref>
          <Meta as="a" target="_blank" regular>
            Donor FAQ
          </Meta>
        </Link>
        <Link href={routes.legal.donor.support} passHref>
          <Meta as="a" target="_blank" regular>
            Donor Support
          </Meta>
        </Link> */}
        <Link href={routes.legal.terms} passHref>
          <Meta as="a" target="_blank" regular>
            Terms of Service Agreement
          </Meta>
        </Link>
        <Link href={routes.legal.privacyPolicy} passHref>
          <Meta as="a" target="_blank" regular>
            Privacy Policy
          </Meta>
        </Link>
      </Grid>

      <Flex flexDirection={['column', 'row']} alignItems="center">
        <Meta regular marginRight={[0, 24]} marginBottom={[18, 0]}>
          Kindest Nonprofit Solutions, Inc. © {new Date().getUTCFullYear()}
        </Meta>
        <KindestLogo />
      </Flex>
    </Wrapper>
  );
}
