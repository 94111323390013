import { useRouter } from 'next/router'
import { getQueryParameter } from '~/utils/get-query-param'

export const useUtmQuery = () => {
  const { query } = useRouter()

  const utm_source = getQueryParameter(query, 'utm_source')
  const utm_medium = getQueryParameter(query, 'utm_medium')
  const utm_campaign = getQueryParameter(query, 'utm_campaign')
  const utm_content = getQueryParameter(query, 'utm_content')

  return {
    ...(utm_source && { utm_source }),
    ...(utm_medium && { utm_medium }),
    ...(utm_campaign && { utm_campaign }),
    ...(utm_content && { utm_content }),
  }
}
