import styled from 'styled-components'
import { Box } from '~/components/layout'
import { Text } from '~/components/typography'
import { media } from '~/styles/media'

export const Content = styled.div({
  whiteSpace: 'pre-wrap',
  '> *': {
    clear: 'both',
  },
  '&::after': {
    content: "''",
    display: 'block',
    clear: 'both',
  },
})

export const Paragraph = styled(Text)({
  marginBottom: 32,
  minHeight: '2rem',

  '&::after': {
    content: "''",
    clear: 'both',
    display: 'table',
  },
})

export const ImageWrapper = styled(Box)<{ align: 'left' | 'right' | 'center' }>(
  props => ({
    img: {
      width: '100%',
    },

    [media.medium]: {
      maxWidth: props.align !== 'center' ? '33.3%' : '100%',
      float: props.align !== 'center' ? (props.align as any) : undefined,
      marginRight: props.align === 'left' ? 48 : undefined,
      marginLeft: props.align === 'right' ? 48 : undefined,

      '& + p': {
        clear:
          props.align !== 'center' ? ('none !important' as 'none') : undefined,
      },
    },
  })
)
