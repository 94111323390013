import { Tab as ReachTab, TabList as ReachTabList } from '@reach/tabs'
import styled from 'styled-components'
import { colors } from '~/styles/colors'
import { fonts } from '~/styles/fonts'

export const Wrapper = styled.div({
  borderBottom: colors.border,
})

export const Container = styled.div({
  maxWidth: 1216,
  margin: '0 auto',
  height: 78,
  overflow: 'auto',
})

export const List = styled(ReachTabList)({
  height: '100%',
  display: 'flex',
  alignItems: 'stretch',
  padding: '0 20px',
})

export const Tab = styled(ReachTab)({
  position: 'relative',
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
  fontWeight: fonts.text.medium,
  color: colors.dark,

  '&:not(:last-of-type)': {
    marginRight: 50,
  },

  "&[aria-disabled='true']": {
    display: 'none',
  },

  "&[aria-selected='true']:before": {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: 4,
    background: colors.dark,
    left: 0,
    bottom: 0,
  },
})
