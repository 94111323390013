import styled from 'styled-components'
import { Button, Props } from '~/components/button'
import { colors } from '~/styles/colors'
import { media } from '~/styles/media'

export const ButtonBody = styled.div({
  display: 'flex',
  gap: '0.75rem',
  justifyContent: 'center',
  alignItems: 'center',
  height: '3rem',
})

export const DonateButton = styled(Button)<
  {
    theme: 'default' | 'chariot'
  } & Props
>(
  props =>
    props.theme === 'chariot' && {
      background: colors.chariot.darkBlue,

      '&:hover': {
        background: colors.chariot.lightBlue,
        boxShadow: `0 0 0 1px ${colors.chariot.lightBlue} inset`,
      },
    },
  {
    minWidth: '20rem',
    height: '3rem',
    borderRadius: '0.25rem',
  }
)

export const DonateButtonsWrapper = styled.div<{ isMobileMarginTop: boolean }>(
  props => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: props.isMobileMarginTop ? '1rem' : '0rem',
    justifyItems: 'center',
    marginBottom: '1.5rem',
    [media.large]: {
      marginTop: '0.75rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.75rem',
      justifyItems: 'center',
      marginBottom: 0,
    },
  })
)
