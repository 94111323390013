import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react'

export enum PageTab {
  general = 1,
  imageAndVideo = 2,
  story = 3,
  donationTiers = 4,
  goals = 5,
}

type Context = {
  setTab: (tab: PageTab) => void
}

const PagePreviewContext = createContext<Context | undefined>(undefined)

export const usePagePreview = (): Context | undefined => {
  const context = useContext(PagePreviewContext)

  return context
}

type ProviderProps = {
  children?: ReactNode
  setTabIndex: (index: number) => void
}

export const PagePreviewProvider: FC<ProviderProps> = ({
  children,
  setTabIndex,
}) => {
  const setTab = useCallback(
    (tab: PageTab) => {
      window.scrollTo(0, 0)
      setTabIndex(tab)
    },
    [setTabIndex]
  )

  const value = useMemo(
    () => ({
      setTab,
    }),
    [setTab]
  )

  return (
    <PagePreviewContext.Provider value={value}>
      {children}
    </PagePreviewContext.Provider>
  )
}
