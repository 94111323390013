import { Amount } from '~/components/amount'
import { Box } from '~/components/layout'
import { Meta } from '~/components/typography'
import { colors } from '~/styles/colors'
import { PreviewGoals } from './components/preview-goals'
import { AmountText } from './style'

type Props = {
  currentAmount: number
  nonprofitName: string
  enableDonationAmonunt: boolean
  title: string
}

export const NoGoals = ({
  currentAmount,
  nonprofitName,
  enableDonationAmonunt,
  title,
}: Props) => {
  return (
    <Box
      padding="40px 16px"
      border={colors.border}
      style={{ textAlign: 'center', borderRadius: 2 }}
    >
      {enableDonationAmonunt ? (
        <AmountText>
          <Amount>{currentAmount}</Amount> raised
        </AmountText>
      ) : (
        <h1 className="mb-2 text-2xl font-bold lg:text-3xl">{title}</h1>
      )}

      <Meta regular>Created by {nonprofitName}</Meta>
      <PreviewGoals />
    </Box>
  )
}
