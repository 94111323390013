interface Props {
  googleTagManagerId: string
}

export const GoogleTagManagerBodyIframe = ({ googleTagManagerId }: Props) => {
  return (
    <>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
    </>
  )
}
