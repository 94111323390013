import { TabPanel, TabPanels, Tabs } from '@reach/tabs'
import clsx from 'clsx'
import { NextPage } from 'next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { CoverImage } from '~/components/cover-image'
import { Box } from '~/components/layout'
import { Link } from '~/components/link'
import { useModal } from '~/components/modal'
import { PaymentTypeSelect } from '~/components/payment-type-select'
import { Heading, Meta } from '~/components/typography'
import { routes } from '~/config/routes'
import { PageBySlugQuery } from '~/graphql/page-by-slug'
import { FundraisingPageLayout } from '~/layouts/fundraising-page-layout'
import { PageStatus } from '~/types/graphql'
import { isEmpty } from '~/utils/is-empty'

import { DonationTiers } from './components/donation-tiers'
import { Goals } from './components/goals'
import { NoGoals } from './components/no-goals'
import { SocialButtons } from './components/social-buttons'
import { TabDonations } from './components/tab-donations'
import { TabList } from './components/tab-list'
import { TabStory } from './components/tab-story'
import {
  Body,
  GoalWrapper,
  Hero,
  HeroTitle,
  Main,
  Side,
  SocialButtonsDesktop,
  SocialButtonsMobile,
} from './style'

const TabFundraisers = dynamic(() => import('./components/tab-fundraisers'))
const FundraiserDetail = dynamic(() => import('./components/fundraiser-detail'))
const VideoPlayer = dynamic(() => import('./components/video-player'))
const PreviewCoverImage = dynamic(
  () => import('./components/preview-cover-image')
)

const ModalThankYou = dynamic(() => import('./components/modal-thank-you'))

type Props = {
  page: PageBySlugQuery['pageBySlug']
  fundraiser?: PageBySlugQuery['pageBySlug']['fundraisers'][0]
  hasDonations: boolean
}

export const FundraisingPage: NextPage<Props> = ({
  page,
  fundraiser,
  hasDonations,
}) => {
  const router = useRouter()
  const thankYouModal = useModal()

  const {
    status,
    nonprofit,
    imageUrl,
    videoUrl,
    slug,
    title,
    goals,
    donationTiers,
    story,
    currentAmount,
    hasImage,
    fundraisers,
    fundraisersTitle,
    pageFeatures,
  } = page

  const closed = status === PageStatus.Closed
  const enableGoals = !isEmpty(goals) && page.pageFeatures.donationAmount

  useEffect(() => {
    if (router.query.donation) {
      thankYouModal.show()
    }
    return () => {
      thankYouModal.hide()
    }
  }, [router.query])

  return (
    <FundraisingPageLayout
      page={page}
      leftContent={
        <Link href={routes.donorPortal.home}>
          <Meta as="a">My Donations</Meta>
        </Link>
      }
      rightContent={
        closed === false && (
          <SocialButtonsDesktop>
            <SocialButtons slug={slug!} fundraiserId={fundraiser?.id} />
          </SocialButtonsDesktop>
        )
      }
    >
      <Hero
        className={clsx({
          'lg:!pt-12': enableGoals === false,
        })}
      >
        {page.pageFeatures.donationAmount && <HeroTitle>{title}</HeroTitle>}
        <Main>
          {!hasImage && <PreviewCoverImage />}
          {videoUrl && <VideoPlayer url={videoUrl} />}
          <CoverImage width={800} imageUrl={imageUrl} />
        </Main>
        <Side>
          {closed == false && (
            <SocialButtonsMobile>
              <SocialButtons slug={slug!} fundraiserId={fundraiser?.id} />
            </SocialButtonsMobile>
          )}

          <GoalWrapper>
            <PaymentTypeSelect
              route={routes.fundraisingPage.checkout(slug!, fundraiser?.id).as}
              page={page}
            />
            <>
              {fundraiser ? (
                <FundraiserDetail fundraiser={fundraiser} />
              ) : !enableGoals ? (
                <NoGoals
                  currentAmount={currentAmount}
                  nonprofitName={nonprofit.name}
                  enableDonationAmonunt={page.pageFeatures.donationAmount}
                  title={title}
                />
              ) : (
                <Goals currentAmount={currentAmount} goals={goals} />
              )}
            </>
          </GoalWrapper>
        </Side>
      </Hero>

      <Tabs>
        <TabList
          showDonations={pageFeatures.communityTab === true && hasDonations}
          showFundraisers={
            pageFeatures.peerToPeerTab === true &&
            fundraiser === undefined &&
            !isEmpty(fundraisers)
          }
          fundraisersTitle={page.fundraisersTitle}
        />
        <Body>
          <TabPanels style={{ flex: 1 }}>
            <TabPanel>
              <TabStory
                story={story ? JSON.parse(story) : []}
                nonprofit={nonprofit}
                fundraiser={fundraiser}
              />
            </TabPanel>

            <TabPanel>
              <DonationTiers
                closed={closed}
                slug={slug!}
                donationTiers={donationTiers}
                fundraiserId={fundraiser?.id}
                allowCustomAmount={page.allowCustomAmount}
              />
            </TabPanel>

            <TabPanel>
              {hasDonations && (
                <TabDonations slug={slug!} fundraiserId={fundraiser?.id} />
              )}
            </TabPanel>

            <TabPanel>
              {!fundraiser && !isEmpty(fundraisers) && (
                <TabFundraisers
                  slug={slug!}
                  fundraisers={fundraisers}
                  fundraisersTitle={fundraisersTitle}
                />
              )}
            </TabPanel>
          </TabPanels>

          <Box flex="0 0 312px" className="hidden md:block lg:ml-[6.25rem]">
            <Heading level={4} marginBottom={24}>
              Donate Now
            </Heading>
            <DonationTiers
              closed={closed}
              slug={slug!}
              donationTiers={donationTiers}
              fundraiserId={fundraiser?.id}
              allowCustomAmount={page.allowCustomAmount}
            />
          </Box>
        </Body>
      </Tabs>
      {router.query.donation ? (
        <ModalThankYou
          routeTo={routes.fundraisingPage.home(slug!, fundraiser?.id).as}
          returnLabel={'Go to Fundraising Page'}
          orderId={router.query.donation.toString()}
          fundraiserId={fundraiser?.id}
          nonprofit={nonprofit}
          slug={slug!}
          {...thankYouModal}
        />
      ) : (
        <></>
      )}
    </FundraisingPageLayout>
  )
}
