import styled from 'styled-components'
import { colors } from '~/styles/colors'
import { fonts } from '~/styles/fonts'

export const Wrapper = styled.a({
  padding: 26,
  border: colors.border,
  borderRadius: 2,

  '&:hover': {
    background: colors.soft,
  },
})

export const TierHeading = styled.div({
  fontSize: '1.25rem',
  lineHeight: 1.6,
  fontWeight: fonts.text.medium,
  color: colors.dark,
  marginBottom: 6,
})
