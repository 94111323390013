import dayjs from 'dayjs'
import relativeTimePlugin from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTimePlugin)

export const formatRelativeTime = (config: dayjs.ConfigType) =>
  dayjs().to(config)

type Props = {
  children: dayjs.ConfigType
}

export const RelativeTime = ({ children }: Props) => {
  return <>{formatRelativeTime(children)}</>
}
