import styled from 'styled-components'
import { Button, Props } from '~/components/button'
import { colors } from '~/styles/colors'
import { fonts } from '~/styles/fonts'
import { media } from '~/styles/media'

export const Hero = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 1256,
  margin: '0 auto',
  padding: '0 20px',

  [media.large]: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})

export const HeroTitle = styled.h1({
  fontSize: '1.25rem',
  fontWeight: fonts.heading.weight,
  padding: '20px 0',
  textAlign: 'center',
  color: colors.dark,
  order: 2,

  [media.large]: {
    fontSize: '2rem',
    lineHeight: 1.75,
    padding: '36px 0 35px',
    order: 1,
    width: '100%',
  },
})

export const Main = styled.div({
  order: 1,
  margin: '0 -20px',
  position: 'relative',

  [media.large]: {
    margin: 0,
    order: 2,
    flex: '1 0.5 50%',
    marginRight: 32,
  },
})

export const Side = styled.div({
  order: 3,
  display: 'flex',
  flexDirection: 'column-reverse',

  [media.large]: {
    flexDirection: 'column',
    flex: '0 0 384px',
  },
})

export const Body = styled.div({
  padding: 30,

  [media.large]: {
    display: 'flex',
    maxWidth: 1132,
    margin: '0 auto',
    padding: '44px 30px 0',
  },
})

export const GoalWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  [media.large]: {
    flexDirection: 'column-reverse',
    width: 400,
  },
})

export const DonateButtonsWrapper = styled.div<{ isMobileMarginTop: boolean }>(
  props => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: props.isMobileMarginTop ? '1rem' : '0rem',
    justifyItems: 'center',
    marginBottom: '1.5rem',
    [media.large]: {
      marginTop: '0.75rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.75rem',
      justifyItems: 'center',
      marginBottom: 0,
    },
  })
)

export const ButtonBody = styled.div({
  display: 'flex',
  gap: '0.75rem',
  justifyContent: 'center',
  alignItems: 'center',
})

export const DonateButton = styled(Button)<
  {
    theme: 'default' | 'chariot'
  } & Props
>(
  props =>
    props.theme === 'chariot' && {
      background: colors.chariot.darkBlue,

      '&:hover': {
        background: colors.chariot.lightBlue,
        boxShadow: `0 0 0 1px ${colors.chariot.lightBlue} inset`,
      },
    },
  {
    minWidth: '20rem',
  }
)

export const SocialButtonsMobile = styled.div({
  display: 'flex',
  marginTop: '1.25rem',

  [media.large]: {
    display: 'none',
  },
})

export const SocialButtonsDesktop = styled.div({
  display: 'none',

  [media.large]: {
    display: 'flex',
  },
})
