import { useEffect, useState } from 'react'
import { SimpleImg } from 'react-simple-img'
import { Box } from '~/components/layout'
import { env } from '~/config/env'

type Props = {
  width: number
  maxWidth?: boolean
  imageUrl: Maybe<string>
}

export const CoverImage = ({ width, maxWidth, imageUrl }: Props) => {
  const [previewUrl, setPreviewUrl] = useState<string>()

  useEffect(() => {
    if (!imageUrl) {
      return
    }
    const url = new URL(imageUrl)
    let options = JSON.parse(atob(url.pathname.substr(1)))

    options = {
      ...options,
      edits: {
        ...options.edits,
        blur: 3,
        resize: {
          ...options.edits.resize,
          width: 260,
          height: 146,
        },
        jpeg: {
          quality: 75,
        },
      },
    }

    const base64 = btoa(JSON.stringify(options))
    setPreviewUrl(`${env.aws.cloudFrontEndpointImages}/${base64}`)
  }, [imageUrl])

  return (
    <Box width="100%" maxWidth={maxWidth ? width : undefined}>
      <SimpleImg
        key={previewUrl}
        width={width}
        height={width / (16 / 9)}
        applyAspectRatio
        src={imageUrl!}
        placeholder={previewUrl}
        style={{
          border: '1px solid rgba(50, 84, 151, 0.15)',
          borderRadius: 1,
        }}
      />
    </Box>
  )
}
