import { useState, useEffect } from 'react'

const useCountdown = (datetime: number) => {
  const [days, setDays] = useState<number>()
  const [hours, setHours] = useState<number>()
  const [minutes, setMinutes] = useState<number>()
  const [seconds, setSeconds] = useState<number>()

  useEffect(() => {
    var updateTime = setInterval(() => {
      var now = new Date().getTime()
      var difference = datetime - now

      if (difference < 0) {
        setDays(0)
        setHours(0)
        setMinutes(0)
        setSeconds(0)
      } else {
        setDays(Math.floor(difference / (1000 * 60 * 60 * 24)))
        setHours(
          Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        )
        setMinutes(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)))
        setSeconds(Math.floor((difference % (1000 * 60)) / 1000))
      }
    })

    return () => {
      clearInterval(updateTime)
    }
  }, [datetime])

  return { days, hours, minutes, seconds }
}

export default useCountdown
