export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

type Props = {
  children: number
}

export const formatAmount = (value: number): string => {
  let amount = formatter.format(value / 100)
  if (amount.endsWith('.00')) {
    amount = amount.replace('.00', '')
  }
  return amount
}

export const Amount = ({ children }: Props) => {
  return <>{formatAmount(children)}</>
}
