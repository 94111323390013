import dynamic from 'next/dynamic'
import { usePagePreview, PageTab } from '~/context/page-preview'

const PreviewPlaceholder = dynamic(import('../../../preview-placeholder'), {
  ssr: false,
})

export const PreviewTiers = () => {
  const context = usePagePreview()

  if (!context) {
    return null
  }

  return (
    <PreviewPlaceholder
      onClick={() => context.setTab(PageTab.donationTiers)}
      height={156}
    >
      Add Donation Tiers
    </PreviewPlaceholder>
  )
}
