import { TabListProps } from '@reach/tabs'

import { Wrapper, Container, Tab, List } from './style'

type Props = TabListProps & {
  showDonations: boolean
  showFundraisers: boolean
  fundraisersTitle: string
}

export const TabList = ({
  showDonations,
  showFundraisers,
  fundraisersTitle,
  ...rest
}: Props) => {
  return (
    <Wrapper>
      <Container>
        <List {...rest}>
          <Tab>Story</Tab>
          <Tab className="md:hidden">Donation Tiers</Tab>
          <Tab disabled={!showDonations}>Donations</Tab>
          <Tab disabled={!showFundraisers}>{fundraisersTitle}</Tab>
        </List>
      </Container>
    </Wrapper>
  )
}
