import dynamic from 'next/dynamic'
import { usePagePreview, PageTab } from '~/context/page-preview'

const PreviewPlaceholder = dynamic(import('../../../preview-placeholder'), {
  ssr: false,
})

export const PreviewGoals = () => {
  const context = usePagePreview()

  if (!context) {
    return null
  }

  return (
    <PreviewPlaceholder
      onClick={() => context.setTab(PageTab.goals)}
      height={56}
      margin="24px 0 -24px"
    >
      Add Goals
    </PreviewPlaceholder>
  )
}
