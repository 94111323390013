import styled from 'styled-components'
import { colors } from '~/styles/colors'
import { media } from '~/styles/media'

export const Wrapper = styled.footer({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '46px 30px',
  borderTop: colors.border,

  [media.medium]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '46px 80px',
  },
})
