import { useEffect } from 'react'
import { useIntersectionObserver } from './use-intersection-observer'

type Options = {
  hasMore: boolean
  onLoadMore: () => void
}

export const useInfinityScroll = ({ onLoadMore, hasMore }: Options) => {
  const [entry, setRef] = useIntersectionObserver({
    threshold: 0,
    rootMargin: '200px',
  })

  useEffect(() => {
    if (entry?.isIntersecting && hasMore) {
      onLoadMore()
    }
  }, [entry, hasMore])

  return [setRef]
}
