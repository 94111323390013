import { useRouter } from 'next/router'
import ChariotConnect from 'react-chariot-connect'
import { PageBySlugQuery } from '~/graphql/page-by-slug'
import { useUtmQuery } from '~/hooks/use-utm-query'
import { PageStatus } from '~/types/graphql'

import LockIcon from './assets/lock-icon.svg'
import { ButtonBody, DonateButton, DonateButtonsWrapper } from './style'

type Props = {
  page: PageBySlugQuery['pageBySlug']
  fundraiser?: PageBySlugQuery['pageBySlug']['fundraisers'][0]
  route: string
  disabled?: boolean
}

export const PaymentTypeSelect = ({ page, route, disabled = false }: Props) => {
  const utmQuery = useUtmQuery()
  const router = useRouter()

  const { status } = page
  const closed = status === PageStatus.Closed

  const onDonationRequest = () => {
    return {
      metadata: {
        fundraiserPageName: page.title,
      },
    }
  }
  const onSuccess = (e: any) => {
    const detail = e.detail
    const grantIntent = e.detail.grantIntent
    const chariotSessionData = {
      workflowSessionId: detail.workflowSessionId,
      userFriendlyId: grantIntent.userFriendlyId,
      fundId: grantIntent.fundId,
      amount: grantIntent.amount,
    }

    sessionStorage.setItem(
      'chariotSessionData',
      JSON.stringify(chariotSessionData)
    )
    router.push(`${route}?chariot=${chariotSessionData.workflowSessionId}`)
  }
  const onExit = () => null

  return (
    <DonateButtonsWrapper isMobileMarginTop={!page.pageFeatures.donationAmount}>
      <DonateButton
        theme="default"
        size="large"
        href={route}
        query={utmQuery}
        disabled={closed || disabled}
      >
        <ButtonBody>
          <LockIcon />
          {closed ? 'Campaign Has Ended' : 'Donate with Credit Card'}
        </ButtonBody>
      </DonateButton>
      {page.pageFeatures.chariotButton ? (
        <ChariotConnect
          cid={page.chariotConnectId!}
          onDonationRequest={onDonationRequest}
          theme="KindestTheme"
          disabled={closed || disabled}
          onSuccess={onSuccess}
          onExit={onExit}
        />
      ) : (
        <></>
      )}
    </DonateButtonsWrapper>
  )
}
