import styled from 'styled-components'
import { fonts } from '~/styles/fonts'
import { colors } from '~/styles/colors'

export const AmountText = styled.div({
  fontSize: '2rem',
  fontWeight: fonts.text.medium,
  color: colors.dark,
  marginBottom: 8,
})
