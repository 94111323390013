import styled from 'styled-components'
import { colors } from '~/styles/colors'
import { fonts } from '~/styles/fonts'
import { media } from '~/styles/media'

export const Wrapper = styled.div({
  // flex: '0 0 18.563rem',
  flex: 0,
  border: colors.border,
  borderRadius: 2,
})

export const Header = styled.header({
  paddingLeft: 24,
  height: 48,
  display: 'flex',
  borderBottom: colors.border,
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const HeaderButton = styled.button({
  width: 48,
  height: 47,
  borderLeft: colors.border,
  textAlign: 'center',

  '&:hover': {
    background: colors.soft,
  },
})

export const Body = styled.div({
  padding: '1.5rem',
})

export const GoalAmount = styled.div({
  fontSize: '1rem',
  lineHeight: 2,
  color: colors.dark,
  marginBottom: 12,

  strong: {
    fontWeight: fonts.text.medium,
  },
})

export const ProgressBar = styled.div({
  background: colors.light,
  marginBottom: 16,
})

export const Progress = styled.div({
  height: 8,
  background: colors.primary.default,
  transition: 'width .3s ease',
})

export const BodyText = styled.p({
  fontSize: '0.875rem',
  lineHeight: 1.8,
  color: colors.dark,

  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': '3',
  '-webkit-box-orient': 'vertical',
})

export const CountDown = styled.div({
  marginTop: '1rem',
  display: 'flex',
  flexDirection: 'row',
  fontSize: '1.5rem',
  fontWeight: 500,
  color: colors.dark,
  justifyContent: 'center',
  gap: '0.75rem',
})

export const CountDownBox = styled.div<{ disabled: boolean }>(props => ({
  alignItems: 'center',
  display: 'flex',
  backgroundColor: props.disabled ? '#F3F6F9' : '#FFC997',
  flexDirection: 'column',
  textAlign: 'center',
  fontWeight: 500,
  color: props.disabled ? colors.slateGray : colors.dark,
  padding: '5px 20px 10px 20px',
  width: 80,
  [media.maxSmall]: {
    width: 60,
  },
  [media.maxLarge]: {
    width: '100%',
  },
}))

export const CountDownBoxValue = styled.label({
  fontSize: '1.5rem',
  fontWeight: 500,
})

export const CountDownBoxHeader = styled.label({
  fontSize: '0.875rem',
  fontWeight: 400,
})
