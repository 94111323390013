import { Heading } from '~/components/typography'
import { useInfinityScroll } from '~/hooks/use-infinity-scroll'

import { Post } from './components/post'
import { useCommunityQuery } from './graphql/community'

type Props = {
  slug: string
  fundraiserId?: string
}

const PAGE_SIZE = 10

export const TabDonations = ({ slug, fundraiserId }: Props) => {
  const { data, fetchMore } = useCommunityQuery({
    variables: {
      slug,
      page: {
        page: 0,
        pageSize: PAGE_SIZE,
      },
      fundraiserId,
    },
    ssr: false,
    fetchPolicy: 'cache-and-network',
  })

  const hasMore = data
    ? data.community.pageInfo.page < data.community.pageInfo.totalPages - 1
    : false

  const [setRef] = useInfinityScroll({
    hasMore,
    onLoadMore: () => {
      fetchMore({
        variables: {
          page: {
            page: data!.community.pageInfo.page + 1,
            pageSize: PAGE_SIZE,
          },
        },
        updateQuery: (
          prev: { community: { posts: string | any[] } },
          { fetchMoreResult }: any
        ) => {
          if (!fetchMoreResult) {
            return prev
          }
          const { posts, pageInfo } = fetchMoreResult.community
          return {
            ...fetchMoreResult,
            __typename: 'Query',
            community: {
              __typename: 'CommunityList',
              pageInfo,
              posts: prev.community.posts.concat(posts),
            },
          }
        },
      })
    },
  })

  if (!data) {
    return null
  }

  const { posts, pageInfo } = data.community
  const { totalItems } = pageInfo

  return (
    <div>
      <Heading level={4} marginBottom={24}>
        {totalItems} donations
      </Heading>

      <div>
        {posts?.map(post => (
          <Post
            key={post.id}
            slug={slug}
            post={post}
            isFundraiser={Boolean(fundraiserId)}
          />
        ))}
        <div ref={setRef} />
      </div>
    </div>
  )
}
