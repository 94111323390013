import dynamic from 'next/dynamic';
import { Box } from '~/components/layout';
import { Heading, Meta, Text } from '~/components/typography';
import { PageBySlugQuery } from '~/graphql/page-by-slug';
import { colors } from '~/styles/colors';
import { PageNonprofit } from '~/types/graphql';
import { normalizeUrl } from '~/utils/normalize-url';

import { PreviewStory } from './components/preview-story';
import { Content, ImageWrapper, Paragraph } from './style';

import type {
  EditorText,
  EditorValue,
} from '~/pages/dashboard/components/editor'
const ReactPlayer = dynamic(() => import('react-player'), { ssr: false })
const FundraiserAbout = dynamic(() => import('./components/fundraiser-about'))

type Props = {
  story: EditorValue
  nonprofit: PageNonprofit
  fundraiser?: PageBySlugQuery['pageBySlug']['fundraisers'][0]
}

const isText = (value: any): value is EditorText => {
  return typeof value.text === 'string'
}

export const TabStory = ({ story, nonprofit, fundraiser }: Props) => {
  return (
    <div>
      {fundraiser?.description && <FundraiserAbout fundraiser={fundraiser} />}

      {story.length === 0 && <PreviewStory />}

      <Content>{story.map(serialize)}</Content>

      <Meta
        regular
        color={colors.slateGray}
        paddingY={24}
        style={{ borderTop: colors.border }}
      >
        {nonprofit.taxNote}
      </Meta>
    </div>
  )
}

const serialize = (node: any, index: number, array: any[]): any => {
  if (isText(node)) {
    let text: React.ReactNode = node.text

    if (node.bold) {
      text = <strong key={'bold' + index}>{text}</strong>
    }

    if (node.italic) {
      text = <em key={'italic' + index}>{text}</em>
    }

    return text
  }

  // TODO: Fix types
  const children = (node.children as any).map(serialize)

  switch (node.type) {
    case 'paragraph': {
      return <Paragraph key={node.type + index}>{children}</Paragraph>
    }

    case 'heading': {
      return (
        <Heading key={index} level={4} marginBottom={24}>
          {children}
        </Heading>
      )
    }

    case 'image': {
      return (
        <ImageWrapper
          key={index}
          marginBottom={array[index + 1]?.type === 'paragraph' ? undefined : 32}
          align={node.align}
        >
          <img src={node.url} loading="lazy" />
        </ImageWrapper>
      )
    }

    case 'video': {
      return (
        <Box
          key={index}
          position="relative"
          paddingTop="56.25%"
          marginBottom={32}
          style={{
            borderRadius: 2,
            overflow: 'hidden',
          }}
        >
          <ReactPlayer
            url={node.url}
            width="100%"
            height="100%"
            style={{ position: 'absolute', top: 0, left: 0 }}
          />
        </Box>
      )
    }

    case 'link': {
      return (
        <Text
          as="a"
          key={node.type + index}
          href={normalizeUrl(node.url)}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: colors.primary.default,
            textDecoration: 'underline',
          }}
        >
          {children}
        </Text>
      )
    }

    case 'list': {
      return (
        <ul
          key={node.type + index}
          style={{ listStyle: 'disc', paddingLeft: 26, marginBottom: 32 }}
        >
          {children}
        </ul>
      )
    }

    case 'list-item': {
      return (
        <Text as="li" key={node.type + index}>
          {children}
        </Text>
      )
    }

    // not used in story
    case 'placeholder-inline': {
      return null
    }

    case 'placeholder-block': {
      return null
    }

    case 'button': {
      return null
    }
  }
}
